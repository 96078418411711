import { ToastId } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useCallback } from 'react'
import { Icons } from 'stylewhere/components'
import { SystemError } from 'stylewhere/shared/errors'
import { closeModal, openModal } from 'stylewhere/shared/utils'
import { ErrorDetailsModal } from './modals/ErrorDetailsModal'

export interface CustomToastProps {
  id: ToastId
  title: string
  description: string
  onClose?: () => void
  err?: SystemError
}

function errorToErrorDescription(err?: SystemError) {
  if (err instanceof Error) {
    return err.message
  }
  if (typeof err === 'string') {
    return err
  }
  return 'Unknown error'
}

export const CustomToast = (props: CustomToastProps) => {
  const { id, onClose, title, description, err } = props

  const errorDescription = errorToErrorDescription(err)
  const onClickExpand = useCallback(() => {
    const MODAL_ID = `errorDetailsModal_${id}`
    openModal({
      id: MODAL_ID,
      modal: (
        <ErrorDetailsModal errorDetail={errorDescription} isOpen title={'Error Details'} onClose={() => closeModal(MODAL_ID)} />
      ),
    })
  }, [errorDescription, id])

  return (
    <CustomToastBox>
      <IconBox>
        <Icons.Close className={'svgColored'} style={{ color: 'red' }} />
      </IconBox>
      <TextBox>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {err && <ExpandBox onClick={onClickExpand}>[expand]</ExpandBox>}
      </TextBox>
      <CloseBox>
        <Icons.Cross width={8} height={8} className={'svgColored'} style={{ color: 'black', cursor: 'pointer' }} onClick={onClose} />
      </CloseBox>
    </CustomToastBox>
  )
}

const CustomToastBox = styled.div`
  padding: 5px;
  font-style: normal;
  border-radius: 5px;
  border-width: 1px;
  border-color: #000000;
  background-color: rgb(251, 234, 232);
  display: flex;
`
const CloseBox = styled.div`
  padding: 5px;
`
const IconBox = styled.div`
  padding: 5px;
`
const TextBox = styled.div`
  padding: 2px;
`
const Title = styled.div`
  font-weight: bold;
  padding: 2px;
`
const Description = styled.div`
  padding: 2px;
`
const ExpandBox = styled.a`
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
  color: rgb(0, 0, 110);
  :focus {
    outline: black;
  }
  :hover {
    text-decoration: underline;
    filter: brightness(80%);
  }
`
