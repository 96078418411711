import { OperationConfigurations } from 'core/api'
import { RawSchemaField } from 'stylewhere/shared'
import { OperationUserSetting } from 'stylewhere/types'
import { navigate } from './router'
import { showToast } from './utils'

interface BaseOperationConfig {
  type:
    | 'TRANSFER_TO_ZONE'
    | 'SIMPLE_ITEM_LIST'
    | 'SIMPLE_PARCEL_LIST'
    | 'INBOUND'
    | 'OUTBOUND'
    | 'PACKING'
    | 'SORTING'
    | 'ENCODING'
    | 'INVENTORY'
    | 'TAG_INFO'
    | 'SIMPLE_ITEM_SESSION_LIST'
  id: string
  creationDate: Date
  lastModifyDate: Date
  code: string
  description: string
  displayPriority: number
  formSchema?: RawSchemaField[]
  enabled: boolean
  clients: string[]
  actions: any[]
  attributes: {
    key: string
    attr: string
  }
}

export interface RawOperationConfig extends BaseOperationConfig {
  options: {
    customExtensionCode: string
    errorStates: string
    ignoreStates: string
    warningStates: string
    ignoreWithReasonStates: string
    readingMode: 'rfid' | 'mixed' | 'barcode'
    shippingMode: 'parcelByParcel' | 'multiParcel' | 'shipment'
    canConfirmWithMissing: 'no' | 'yes' | 'withWarning'
    canConfirmWithOverQuantity: 'no' | 'yes' | 'withWarning'
    canConfirmWithUnexpected: 'no' | 'yes' | 'withWarning'
    createChecklistOnConfirm: 'true' | 'false'
    hasChecklist: 'true' | 'false'
    showModalOnConfirm: 'true' | 'false' | undefined
    persistParcelContentScannings: 'onUserConfirm' | 'onBatchRead'
    hasMovement: 'true' | 'false'
    autostartAntenna: 'true' | 'false'
    autostopAntennaTimeout: number | undefined
    startWithRfidSearch: 'true' | 'false'
    confirmOnItemRead: 'true' | 'false'
    readingsDisplayMode: 'groupedByProduct' | 'item'
    'stw.core.logistics.commons.config.requiredparcelcode': 'true' | 'false'
    enableOriginZone: 'true' | 'false'
    enablePicking: 'true' | 'false'
    showDeleteButton: 'true' | 'false'
    showCloseButton: 'true' | 'false'
    showCreateButton: 'true' | 'false'
    pricingEnabled: 'true' | 'false'
    refreshCounterTimeout: number | undefined
    formSchemaSingleRow: boolean | undefined | string
    postConfirmAction: string | undefined
    disableWorkstationCheck: boolean | undefined | string
    activeStorageForm: boolean | undefined | string
    enabledCheckItemZone: boolean | undefined | string
    canForceAssociation: string | undefined
    scanIdentifier: 'rfid' | 'rfidAndSerial' | undefined
    forcePin: string | undefined
    forceRetries: number | undefined
    enableSoundFeedbackOk: boolean | undefined | string
    enableSoundFeedbackKo: boolean | undefined | string
    enableSoundFeedbackConfirm: boolean | undefined | string
    batchInterval: 'true' | 'false'
    batchIntervalTime: number | string | undefined
    batchIntervalTagCount: number | string | undefined
    templatePath: string | undefined
    showSessionWarningIfNoQc: boolean | string | undefined
    blockListIfNoQc: boolean | string | undefined
    sessionRework: string | undefined
    sessionUpdatePolicy: string | undefined
    resetReadingsAfterOperation: number | string | undefined
    stopAndResetAntennaTimeout: number | string | undefined
    executionModeForSyncConfirmation: 'sync' | 'async' | undefined
    skipValidation: boolean | undefined | string
    expectOnlyMandatoryIdentifiers: boolean | undefined | string
    uhfTagToWriteRegex: string | undefined
    enableUhfWrite: 'no' | 'gs1' | 'custom' | undefined
    askUserInputEveryTime: 'true' | 'false' | undefined
    antennaTurnWaitingInterval: number | undefined
    automaticRestartAntenna: 'true' | 'false' | undefined
    errorMessageView: 'toast' | 'modal' | undefined
    newReadingsFirst: string | undefined
    saveOnReadActionCode: string | undefined
    startAsnScreen: undefined | 'asnList' | 'inputCode' | 'asnListAndInputCode' | 'no'
    showListOnStart: undefined | 'true' | 'false'
    canCreateParcels: undefined | 'true' | 'false'
    canCreateShipments: undefined | 'true' | 'false'
    removeMode: undefined | 'sku' | 'rfid' | 'identifier' | 'none'
    genericDiscrepancyMessage: undefined | 'true' | 'false'
    reverseAsnOrder: undefined | 'true' | 'false'
    reverseParcelOrder: undefined | 'true' | 'false'
    allowUnpack: undefined | 'true' | 'false' | 'trueWithContentRetrieval'
    shippingReadTimeout?: number
    enableCheckZone?: 'true' | 'false'
    showTransactionDateModal?: 'true' | 'false'
    viewMode?: string
    filterByOperation?: 'true' | 'false'
    filterByPlace?: 'true' | 'false'
    showAlertOnExit?: 'true' | 'false'
    startParcelWithAsn?: 'true' | 'false'
    userSettings?: string
    validateEncodingOperationCode?: string
    scanningContentMode?: 'massive' | 'itemByItem'
  }
}

export interface OperationConfigCore extends BaseOperationConfig {
  itemStatuses: {
    error: string[]
    warning: string[]
    ignore: string[]
    ignoreWithReason: string[]
  }
  autostartAntenna: boolean
  autostopAntennaTimeout: number
  refreshCounterTimeout: number
  formSchemaSingleRow: boolean
  postConfirmAction: string
  activeStorageForm: boolean
  enabledCheckItemZone: boolean
  disableWorkstationCheck: boolean
  readingsDisplayMode: 'groupedByProduct' | 'item'
  removeMode: 'sku' | 'rfid' | 'identifier' | 'none'
  batchInterval: boolean
  batchIntervalTime: number
  batchIntervalTagCount: number
  templatePath: string
  options: any
  executionModeForSyncConfirmation: 'sync' | 'async'
  errorMessageView: 'toast' | 'modal'
  newReadingsFirst: boolean
  saveOnReadActionCode: string | undefined
  showAlertOnExit: boolean
  userSettings: OperationUserSetting[]
}

export interface TransferToZoneOperationConfig extends OperationConfigCore {
  type: 'TRANSFER_TO_ZONE'
}

export interface SimpleItemListOperationConfig extends OperationConfigCore {
  type: 'SIMPLE_ITEM_LIST'
  customExtensionCode: string
  confirmOnItemRead: boolean
}

export interface SimpleParcelListOperationConfig extends OperationConfigCore {
  type: 'SIMPLE_PARCEL_LIST'
  viewMode: string
  showModalOnConfirm: boolean
}

export interface ShippingOperationConfig extends OperationConfigCore {
  type: 'INBOUND' | 'OUTBOUND' | 'PACKING'
  hasChecklist: boolean
  readingMode: 'rfid' | 'mixed' | 'barcode'
  shippingMode: 'parcelByParcel' | 'multiParcel' | 'shipment'
  canConfirmWithMissing: 'no' | 'yes' | 'withWarning'
  canConfirmWithOverQuantity: 'no' | 'yes' | 'withWarning'
  canConfirmWithUnexpected: 'no' | 'yes' | 'withWarning'
  requiredParcelCode?: boolean
  startWithRfidSearch: boolean
  showModalOnConfirm: boolean
  persistParcelContentScannings: 'onUserConfirm' | 'onBatchRead'
  showListOnStart: boolean
  canCreateParcels: boolean
  canCreateShipments: boolean
  removeMode: 'none' | 'sku' | 'identifier' | 'rfid'
  genericDiscrepancyMessage: boolean
  reverseAsnOrder: boolean
  reverseParcelOrder: boolean
  allowUnpack: 'true' | 'false' | 'trueWithContentRetrieval'
  shippingReadTimeout?: number
  filterByOperation?: boolean
  startParcelWithAsn?: boolean
  validateEncodingOperationCode?: string
  scanningContentMode: 'massive' | 'itemByItem'
  filterByPlace?: boolean
}

export interface InboundOperationConfig extends ShippingOperationConfig {
  type: 'INBOUND'
}

export interface OutboundOperationConfig extends ShippingOperationConfig {
  type: 'OUTBOUND'
}

export interface PackingOperationConfig extends ShippingOperationConfig {
  type: 'PACKING'
}

export interface SortingOperationConfig extends OperationConfigCore {
  type: 'SORTING'
  enableOriginZone: boolean
  enablePicking: boolean
  showCreateButton: boolean
  showCloseButton: boolean
  showDeleteButton: boolean
  pricingEnabled: boolean
  itemConfirmMode?: string
  enableCheckZone?: boolean
  showTransactionDateModal?: boolean
}

export interface EncodingOperationConfig extends OperationConfigCore {
  type: 'ENCODING'
  options: {
    initialType: 'order' | 'product' | 'identifier' | 'wam' | 'formSchema'
    canForceAssociation: string
    scanIdentifier: 'rfid' | 'rfidAndSerial'
    forcePin: string
    forceRetries: number
    enableSoundFeedbackOk: boolean | string
    enableSoundFeedbackKo: boolean | string
    enableSoundFeedbackConfirm: boolean | string
    productionOrderRowSelectionMode: 'byOrderAndRowCode' | 'byOrderAndSku' | 'byCode'
    timeoutValidateTags?: number
    timeoutTagsNoReads?: number
    resetReadingsAfterOperation?: number
    skipValidation: boolean
    expectOnlyMandatoryIdentifiers: boolean
    uhfTagToWriteRegex: string
    enableUhfWrite: 'no' | 'gs1' | 'custom'
    askUserInputEveryTime: 'true' | 'false'
    antennaTurnWaitingInterval: number | undefined
    automaticRestartAntenna: 'true' | 'false' | undefined
    encodingTagPassword: string | undefined
    encodingKillTagPassword: string | undefined
    ignoreReadingTagsWithPrefixes: string | undefined
    useTidTargetDuringWrite: 'true' | 'false' | undefined
    onItemAlreadyEncodedFeedback?: 'confirmModal' | 'infoToast'
    forceTagRewrite: 'true' | 'false' | undefined
    ignoreUnknownTags: 'yes' | 'no' | undefined
  }
}

export interface InventoryOperationConfig extends OperationConfigCore {
  type: 'INVENTORY'
}

export interface TagInfoOperationConfig extends OperationConfigCore {
  type: 'TAG_INFO'
}

export interface SessionItemSessionListOperationConfig extends OperationConfigCore {
  type: 'SIMPLE_ITEM_SESSION_LIST'
  resetReadingsAfterOperation: number
  stopAndResetAntennaTimeout: number
}

export type OperationConfig =
  | TransferToZoneOperationConfig
  | SimpleItemListOperationConfig
  | SimpleParcelListOperationConfig
  | InboundOperationConfig
  | OutboundOperationConfig
  | PackingOperationConfig
  | ShippingOperationConfig
  | SortingOperationConfig
  | EncodingOperationConfig
  | InventoryOperationConfig
  | TagInfoOperationConfig
  | SessionItemSessionListOperationConfig

export class RemoteOperationBase {
  operations: OperationConfig[] = []

  async load(): Promise<void> {
    try {
      const rawOperations = await OperationConfigurations.search<RawOperationConfig>({
        size: 10000,
        clients: 'station',
        enabled: true,
        showUserOperations: true,
      })
      if (rawOperations && rawOperations.content) {
        this.operations = rawOperations.content.map((op) => mapOperation(op))
      }
    } catch (error) {
      console.error('Error loading operations', error)
    }
  }

  getOperationConfig<T extends OperationConfig = OperationConfig>(code: string): T {
    const operation = this.operations.find((o) => o.code === code)
    if (!operation) {
      showToast({ status: 'error', description: `Operation ${code} not found.` })
      navigate('/')
    }
    //if (operation) operation.itemStatuses.ignoreWithReason = ['REPLACED_ASSOCIATION']
    return operation as T
  }
}

export function mapOperation<T extends OperationConfig = OperationConfig>(rawOp: RawOperationConfig): T {
  const { options, ...rawOperation } = rawOp
  const {
    errorStates,
    warningStates,
    ignoreStates,
    ignoreWithReasonStates,
    autostartAntenna,
    readingsDisplayMode,
    autostopAntennaTimeout,
    removeMode,
    refreshCounterTimeout,
    formSchemaSingleRow,
    postConfirmAction,
    activeStorageForm,
    enabledCheckItemZone,
    disableWorkstationCheck,
    batchInterval,
    batchIntervalTime,
    batchIntervalTagCount,
    templatePath,
    newReadingsFirst,
    saveOnReadActionCode,
    showAlertOnExit,
    userSettings,
    ...restOptions
  } = options
  const base: OperationConfigCore = {
    ...rawOperation,
    itemStatuses: {
      error: errorStates?.split(',') ?? [],
      warning: warningStates?.split(',') ?? [],
      ignore: ignoreStates?.split(',') ?? [],
      ignoreWithReason: ignoreWithReasonStates?.split(',') ?? [],
    },
    autostartAntenna: autostartAntenna === 'true',
    autostopAntennaTimeout: autostopAntennaTimeout ?? 0,
    refreshCounterTimeout: refreshCounterTimeout ?? 30000,
    readingsDisplayMode: readingsDisplayMode ?? 'groupedByProduct',
    removeMode: removeMode ?? 'none',
    options: restOptions,
    formSchemaSingleRow: formSchemaSingleRow && formSchemaSingleRow === 'true' ? true : false,
    postConfirmAction: postConfirmAction ?? 'disabled',
    activeStorageForm: activeStorageForm && activeStorageForm === 'true' ? true : false,
    enabledCheckItemZone: enabledCheckItemZone && enabledCheckItemZone === 'true' ? true : false,
    disableWorkstationCheck: disableWorkstationCheck && disableWorkstationCheck === 'true' ? true : false,
    batchInterval: batchInterval && batchInterval === 'true' ? true : false,
    batchIntervalTime: batchIntervalTime ? parseInt(batchIntervalTime + '', 10) : 1000,
    batchIntervalTagCount: batchIntervalTagCount ? parseInt(batchIntervalTagCount + '', 10) : 200,
    templatePath: templatePath || '',
    executionModeForSyncConfirmation: options.executionModeForSyncConfirmation || 'sync',
    errorMessageView: options.errorMessageView || 'toast',
    newReadingsFirst: newReadingsFirst && newReadingsFirst === 'true' ? true : false,
    saveOnReadActionCode: saveOnReadActionCode,
    showAlertOnExit: showAlertOnExit === 'true',
    userSettings: userSettings !== undefined ? JSON.parse(userSettings) : [],
  }

  if (base.type === 'INBOUND' || base.type === 'OUTBOUND' || base.type === 'PACKING') {
    const startAsnScreen = options.startAsnScreen ?? 'no'
    let showListOnStart = options.showListOnStart !== undefined ? options.showListOnStart === 'true' : false
    let canCreateShipments = options.canCreateShipments !== undefined ? options.canCreateShipments === 'true' : false
    if (options.canCreateShipments === undefined && ['inputCode', 'asnListAndInputCode'].includes(startAsnScreen))
      canCreateShipments = true
    if (options.showListOnStart === undefined && ['asnList', 'asnListAndInputCode'].includes(startAsnScreen))
      showListOnStart = true

    return {
      ...base,
      type: base.type,
      hasChecklist: options.hasChecklist === 'true',
      readingMode: options.readingMode ?? 'rfid',
      shippingMode: options.shippingMode ?? 'parcelByParcel',
      canConfirmWithMissing: options.canConfirmWithMissing ?? 'withWarning',
      canConfirmWithOverQuantity: options.canConfirmWithOverQuantity ?? 'withWarning',
      canConfirmWithUnexpected: options.canConfirmWithUnexpected ?? 'withWarning',
      startWithRfidSearch: options.startWithRfidSearch === 'true',
      showModalOnConfirm: options.showModalOnConfirm && options.showModalOnConfirm === 'true' ? true : false,
      persistParcelContentScannings:
        options.persistParcelContentScannings && options.persistParcelContentScannings === 'onBatchRead'
          ? 'onBatchRead'
          : 'onUserConfirm',
      showListOnStart: showListOnStart,
      canCreateParcels: options.canCreateParcels === 'true',
      canCreateShipments: canCreateShipments,
      removeMode: options.removeMode ?? 'none',
      genericDiscrepancyMessage:
        options.genericDiscrepancyMessage && options.genericDiscrepancyMessage === 'true' ? true : false,
      reverseAsnOrder: options.reverseAsnOrder && options.reverseAsnOrder === 'true' ? true : false,
      reverseParcelOrder: options.reverseParcelOrder && options.reverseParcelOrder === 'true' ? true : false,
      allowUnpack: options.allowUnpack ?? 'false',
      shippingReadTimeout: options.shippingReadTimeout ?? 1000,
      filterByOperation: options.filterByOperation && options.filterByOperation === 'true' ? true : false,
      filterByPlace: options.filterByPlace && options.filterByPlace === 'true' ? true : false,
      startParcelWithAsn: options.startParcelWithAsn === 'false' ? false : true,
      validateEncodingOperationCode: options.validateEncodingOperationCode,
      scanningContentMode: options.scanningContentMode ?? 'massive',
      templatePath: options.templatePath ?? (options.scanningContentMode === 'itemByItem' ? 'withEncoding' : ''),
    } as T
  }
  if (base.type === 'SIMPLE_ITEM_LIST') {
    return {
      ...base,
      type: base.type,
      customExtensionCode: options.customExtensionCode,
      confirmOnItemRead: options.confirmOnItemRead === 'true',
    } as T
  }
  if (base.type === 'ENCODING') {
    return {
      ...base,
      type: base.type,
    } as T
  }
  if (base.type === 'SIMPLE_PARCEL_LIST') {
    return {
      ...base,
      type: base.type,
      viewMode: options.viewMode ?? 'parcel',
      showModalOnConfirm: options.showModalOnConfirm && options.showModalOnConfirm === 'true' ? true : false,
    } as T
  }
  if (base.type === 'SORTING') {
    return {
      ...base,
      type: base.type,
      enableOriginZone: options.enableOriginZone && options.enableOriginZone === 'true',
      enablePicking: options.enablePicking && options.enablePicking === 'true',
      showCreateButton: options.showCreateButton && options.showCreateButton === 'true',
      showCloseButton: options.showCloseButton && options.showCloseButton === 'true',
      showDeleteButton: options.showDeleteButton && options.showDeleteButton === 'true',
      pricingEnabled: options.pricingEnabled && options.pricingEnabled === 'true',
      enableCheckZone: options.enableCheckZone && options.enableCheckZone === 'true',
      showTransactionDateModal: options.showTransactionDateModal && options.showTransactionDateModal === 'true',
    } as T
  }
  if (base.type === 'SIMPLE_ITEM_SESSION_LIST') {
    return {
      ...base,
      type: base.type,
      resetReadingsAfterOperation: options.resetReadingsAfterOperation
        ? parseInt(options.resetReadingsAfterOperation + '', 10)
        : 500,
      stopAndResetAntennaTimeout: options.stopAndResetAntennaTimeout
        ? parseInt(options.stopAndResetAntennaTimeout + '', 10)
        : 3000,
    } as T
  }
  return { ...base, type: base.type } as T
}

export default new RemoteOperationBase()
