import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react'
import { CustomToast } from 'stylewhere/components'
import { AppStore, Sounds } from 'stylewhere/shared'
import { SystemError } from 'stylewhere/shared/errors'
import { T, __ } from 'stylewhere/shared/i18n'

interface ToastOptions extends UseToastOptions {
  sound?: boolean
  status: Exclude<UseToastOptions['status'], undefined>
}

const toast = createStandaloneToast()


export const hideToast = (id: string | number) => {
  toast.close(id)
}

export const showToast = ({
  sound = false,
  position = 'bottom-right',
  duration = 3000,
  status,
  isClosable = true,
  ...options
}: ToastOptions) => {
  switch (status) {
    case 'error':
      if (sound) Sounds.error()
      duration = 8000
      break
    case 'warning':
      if (sound) Sounds.error()
      duration = 4000
      break
    case 'success':
      if (sound) Sounds.success()
      break
  }
  return toast({ status, position, duration, isClosable, ...options })
}

export function openFeedbackModal(
  title: string,
  message: string,
  onModalClose?: () => void,
  btnCloseModal?
): Promise<boolean> {
  return new Promise((resolve) => {
    AppStore.showFeedbackModal(title, message, () => (onModalClose ? onModalClose() : resolve(false)), btnCloseModal)
  })
}

export function hideFeedbackModal() {
  AppStore.hideFeedbackModal()
}

export const showToastError = (
  err: any,
  title: string = __(T.error.error),
  modal = false,
  onModalClose?: () => void,
  btnCloseModal = __(T.misc.ok)
) => {
  if (modal) {
    let message = 'Unknown error'
    if (err instanceof Error) {
      message = err.message ? err.message : 'Unknown error'
    } else if (typeof err === 'string') {
      message = err
    }
    return openFeedbackModal(title, message, onModalClose, btnCloseModal)
  }
  if (err instanceof Error) {
    if (err instanceof SystemError || err?.name === 'SystemError') {
      return showToast({
        title,
        status: 'error',
        render: ({ id, onClose }) => (
          <CustomToast
            title={__(T.error.be_generic_error)}
            description={__(T.error.be_generic_error_description)}
            err={err as SystemError}
            id={id}
            onClose={onClose}
          />
        ),
      })
    }
    const description = err.message ? err.message : 'Unknown error'
    return showToast({
      title,
      description,
      status: 'error',
    })
  }
  if (typeof err === 'string') {
    return showToast({
      title,
      description: err,
      status: 'error',
    })
  }
  return showToast({
    title,
    description: 'Generic',
    status: 'error',
  })
}
